<template>
  <div class="">
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Messages') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="()=>$refs['messages-table'].refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <messages-table ref="messages-table"/>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>


<script>
import {mapActions, mapGetters} from 'vuex'
import MessagesTable from "@/views/messages/message-table";

export default {
  components: {
    MessagesTable,
  },
  name: 'clientMessages',
  data: () => ({
    items: [],
    listeners: {
      refresh: () => {
      }
    }
  }),
  watch: {
    filter: {
      deep: true,
      handler() {
      }
    },
  },
  created() {
    let $this = this;

    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  computed: {
    ...mapGetters('data', ['getUserFullName', 'getAllUsers', 'getUser']),
  },
  methods: {
    refresh() {
      this.$root.$emit("crm::messages-table::refresh");
    },

  }
}
</script>

<style scoped>

</style>
